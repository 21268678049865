var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          { staticClass: "container-layout px-0" },
          [
            _c("b-col", { attrs: { md: "12" } }, [
              _c(
                "div",
                { staticClass: "card card-border-blue-light" },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.errors.length
                        ? _c("div", [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(
                                    52,
                                    "Please correct the following error(s)"
                                  )
                                ) + ":"
                              ),
                            ]),
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function (error, i) {
                                return _c("li", { key: i }, [
                                  _vm._v(_vm._s(error)),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: !_vm.isModal ? 9 : 12 } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(125, "Type"),
                                    "label-for": "projectType",
                                    "label-cols": "3",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "projectType",
                                      disabled:
                                        !this.enableProjectTypeChoice ||
                                        _vm.isFilmSingle,
                                      options: _vm.mapTgsProjectType,
                                    },
                                    model: {
                                      value: _vm.project.projectType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.project,
                                          "projectType",
                                          $$v
                                        )
                                      },
                                      expression: "project.projectType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.project.projectType == 0 ||
                              _vm.project.projectType == 3 ||
                              _vm.project.projectType == 4
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  504,
                                                  "Project reference"
                                                ),
                                                "label-for": "projectReference",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "projectReference",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.reference,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "reference",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.reference",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(1, "Title"),
                                                "label-for": "titre",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "titre",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.titre,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "titre",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "project.titre",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.$screen.width > 567
                                            ? _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      class: `${
                                                        _vm.$screen.width < 768
                                                          ? ""
                                                          : "mt-3"
                                                      }`,
                                                      attrs: { lg: "3" },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "fw-700 fs-14 text-color-rhapsody-in-blue",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.labelLegalEntity
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { lg: "9" } },
                                                    [
                                                      _c("SupplierSelector", {
                                                        ref: "projectAdmin",
                                                        attrs: {
                                                          label: "",
                                                          "show-map": false,
                                                          title: `${
                                                            !_vm.isSupplierUpdate
                                                              ? _vm.FormMSG(
                                                                  1205,
                                                                  "Add entity details"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1206,
                                                                  "Update entity details"
                                                                )
                                                          }`,
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              1132,
                                                              "Enter your legal entity name"
                                                            ),
                                                          addlabel: _vm.FormMSG(
                                                            5446,
                                                            "Add"
                                                          ),
                                                          "update-label":
                                                            _vm.FormMSG(
                                                              13,
                                                              "Update"
                                                            ),
                                                          type: "projectAdmin",
                                                          "supplier-id":
                                                            _vm.selectedSupplier,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleAdminOwnerChange,
                                                          "supplier:unselected":
                                                            _vm.onAdminOwnerUnselected,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$screen.width <= 567
                                            ? _c(
                                                "fieldset",
                                                { staticClass: "card-inside" },
                                                [
                                                  _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "card-inside",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.labelLegalEntity
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "SupplierSelector",
                                                            {
                                                              ref: "projectAdmin",
                                                              attrs: {
                                                                label: "",
                                                                "show-map": false,
                                                                title: `${
                                                                  !_vm.isSupplierUpdate
                                                                    ? _vm.FormMSG(
                                                                        1205,
                                                                        "Add entity details"
                                                                      )
                                                                    : _vm.FormMSG(
                                                                        1206,
                                                                        "Update entity details"
                                                                      )
                                                                }`,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1132,
                                                                    "Enter your legal entity name"
                                                                  ),
                                                                addlabel:
                                                                  _vm.FormMSG(
                                                                    5446,
                                                                    "Add"
                                                                  ),
                                                                "update-label":
                                                                  _vm.FormMSG(
                                                                    13,
                                                                    "Update"
                                                                  ),
                                                                type: "projectAdmin",
                                                                "supplier-id":
                                                                  _vm.selectedSupplier,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleAdminOwnerChange,
                                                                "supplier:unselected":
                                                                  _vm.onAdminOwnerUnselected,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    456,
                                                                    "VAT for invoices"
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group",
                                                                {
                                                                  attrs: {
                                                                    append: "%",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "mask",
                                                                            rawName:
                                                                              "v-mask",
                                                                            value:
                                                                              "###",
                                                                            expression:
                                                                              "'###'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            456,
                                                                            "VAT for invoices"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .project
                                                                            .vatPercent,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.project,
                                                                              "vatPercent",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "project.vatPercent",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  1134,
                                                  "Use legal entity data for crew invoicing"
                                                ),
                                                "label-cols": "3",
                                              },
                                            },
                                            [
                                              _vm.project.projectType !== 3 &&
                                              _vm.project.projectType !== 4
                                                ? _c("b-form-checkbox", {
                                                    staticStyle: {
                                                      "margin-top": "4px",
                                                    },
                                                    attrs: {
                                                      size: "lg",
                                                      id: "useFCI",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project
                                                          .useForCrewInvoice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "useForCrewInvoice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.useForCrewInvoice",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.project.useForCrewInvoice ==
                                            false &&
                                          _vm.project.projectType !== 3 &&
                                          _vm.project.projectType !== 4
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1133,
                                                      "Invoice details for crew"
                                                    ),
                                                    "label-for":
                                                      "invoiceDetails",
                                                    "label-cols": "3",
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "invoiceDetails",
                                                      placeholder: this.FormMSG(
                                                        22,
                                                        "Enter invoice details here..."
                                                      ),
                                                      "no-resize": true,
                                                      state:
                                                        _vm.project
                                                          .invoiceDetails
                                                          .length < 4000,
                                                      rows: "6",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project
                                                          .invoiceDetails,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "invoiceDetails",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.invoiceDetails",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.project.projectType == 0 ||
                                          _vm.project.projectType == 3 ||
                                          _vm.project.projectType == 4
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      2,
                                                      "Production"
                                                    ),
                                                    "label-for": "production",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "production",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.production,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "production",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.production",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.project.projectType == 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      20,
                                                      "Co-production"
                                                    ),
                                                    "label-for": "coProducers",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "coProducers",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.coProducers,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "coProducers",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.coProducers",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: this.FormMSG(
                                                  888,
                                                  "Production type"
                                                ),
                                                "label-for": "optionForType",
                                                "label-cols": 3,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.optionForType,
                                                  id: "optionForType",
                                                },
                                                model: {
                                                  value:
                                                    _vm.project.productionType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "productionType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.productionType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: 6 } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: this.FormMSG(
                                                          889,
                                                          "Film length/ avg episode length"
                                                        ),
                                                        "label-for":
                                                          "optionForType",
                                                        "label-cols": 6,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              type: "number",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  890,
                                                                  "minutes"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.project
                                                                  .filmLength,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "filmLength",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "project.filmLength",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "littleBorderRad",
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " min "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: 4 } },
                                                [
                                                  _vm.project.productionType >=
                                                    4 &&
                                                  _vm.project.productionType <=
                                                    10
                                                    ? _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label: this.FormMSG(
                                                              891,
                                                              "Total"
                                                            ),
                                                            "label-for":
                                                              "optionForType",
                                                            "label-cols": 2,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "number",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .project
                                                                        .numEpisode,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.project,
                                                                          "numEpisode",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "project.numEpisode",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-input-group-append",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "littleBorderRad",
                                                                      attrs: {
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              892,
                                                                              "episodes"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: this.FormMSG(
                                                  895,
                                                  "Shooting format"
                                                ),
                                                "label-for": "optionForFormat",
                                                "label-cols": 3,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.optionForFormat,
                                                  id: "optionForFormat",
                                                },
                                                model: {
                                                  value:
                                                    _vm.project.shootingFormat,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "shootingFormat",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.shootingFormat",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.project.projectType == 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      3,
                                                      "Director"
                                                    ),
                                                    "label-for": "realisateur",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "realisateur",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.realisateur,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "realisateur",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.realisateur",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.project.projectType == 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      21,
                                                      "Song and Music"
                                                    ),
                                                    "label-for": "music",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "music",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.songMusic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "songMusic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.songMusic",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.project.projectType === 1
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  505,
                                                  "Project reference"
                                                ),
                                                "label-for": "projectReference",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "projectReference",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.reference,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "reference",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.reference",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  126,
                                                  "Office"
                                                ),
                                                "label-for": "titre",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "titre",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.titre,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "titre",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "project.titre",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  128,
                                                  "Legal entity"
                                                ),
                                                "label-for": "production",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "production",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.production,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "production",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.production",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(129, "CEO"),
                                                "label-for": "realisateur",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "realisateur",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.project.realisateur,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "realisateur",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.realisateur",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.project.projectType == 2
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  506,
                                                  "Project reference"
                                                ),
                                                "label-for": "projectReference",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "projectReference",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.reference,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "reference",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.reference",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  127,
                                                  "Agency"
                                                ),
                                                "label-for": "titre",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "titre",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.titre,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "titre",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "project.titre",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  128,
                                                  "Legal entity"
                                                ),
                                                "label-for": "production",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "production",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.project.production,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "production",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.production",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(129, "CEO"),
                                                "label-for": "realisateur",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "realisateur",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.project.realisateur,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "realisateur",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.realisateur",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: this.FormMSG(80, "Langue"),
                                    "label-for": "optionsForLanguages",
                                    "label-cols": 3,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.optionsForLanguages,
                                      id: "optionsForLanguages",
                                    },
                                    model: {
                                      value: _vm.project.language,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.project, "language", $$v)
                                      },
                                      expression: "project.language",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: this.FormMSG(
                                      801,
                                      "Regional Setting"
                                    ),
                                    "label-for": "regionalSettingOptions",
                                    "label-cols": 3,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.regionalSettingOptions,
                                      id: "regionalSettingOptions",
                                    },
                                    model: {
                                      value: _vm.project.regionalSetting,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.project,
                                          "regionalSetting",
                                          $$v
                                        )
                                      },
                                      expression: "project.regionalSetting",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(4, "Time zone"),
                                    "label-for": "timeZone",
                                    "label-cols": 3,
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "timeZone", type: "text" },
                                    model: {
                                      value: _vm.project.timeZone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.project, "timeZone", $$v)
                                      },
                                      expression: "project.timeZone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(5, "Currency"),
                                    "label-for": "currency",
                                    "label-cols": "3",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "currency",
                                      disabled: !_vm.mapCurrencies.length,
                                      options: _vm.mapCurrencies,
                                    },
                                    model: {
                                      value: _vm.project.currency,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.project, "currency", $$v)
                                      },
                                      expression: "project.currency",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Distance unit"),
                                    "label-for": "distanceUnit",
                                    "label-cols": "3",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "distanceUnit",
                                      disabled: !_vm.mapDistacneUnits.length,
                                      options: _vm.mapDistacneUnits,
                                    },
                                    model: {
                                      value: _vm.project.distanceUnit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.project,
                                          "distanceUnit",
                                          $$v
                                        )
                                      },
                                      expression: "project.distanceUnit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.isFilmSingle
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "fs-14 text-color-rhapsody-in-blue",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  238,
                                                  "Analytic periods"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c("b-form-group", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    ariaDescribedby,
                                                  }) {
                                                    return [
                                                      _c("b-form-radio-group", {
                                                        attrs: {
                                                          id: "itterationAnalytic",
                                                          options:
                                                            _vm.itterationAnalyticOptions,
                                                          "aria-describedby":
                                                            ariaDescribedby,
                                                          name: "radio-inline",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeItterationAnalytic,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.itterationAnalyticSelected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.itterationAnalyticSelected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "itterationAnalyticSelected",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              858765307
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.itterationAnalyticSelected !== 1
                                        ? _c(
                                            "b-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  id: "analyticPeriods",
                                                  options:
                                                    _vm.analyticPeriodOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.project
                                                      .analyticPeriodType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "analyticPeriodType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.analyticPeriodType",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: 6 } }, [
                                    _vm.project.analyticPeriodType !== 2
                                      ? _c(
                                          "div",
                                          [
                                            _vm.project.projectType !== 3 &&
                                            _vm.project.projectType !== 4
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: this.FormMSG(
                                                        48,
                                                        "Start date"
                                                      ),
                                                      "label-for": "startDate",
                                                      "label-cols": "6",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "ml-2",
                                                      attrs: {
                                                        value:
                                                          _vm.project
                                                            .startDate &&
                                                          _vm.project.startDate.split(
                                                            "T"
                                                          )[0],
                                                        type: "date",
                                                        id: "startDate",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.updateStartDate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: 6 } }, [
                                    _c(
                                      "div",
                                      [
                                        !_vm.isFilmSingle &&
                                        (_vm.project.analyticPeriodType === 1 ||
                                          _vm.project.analyticPeriodType ===
                                            2 ||
                                          _vm.project.analyticPeriodType === 4)
                                          ? _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    410,
                                                    "Shooting start date"
                                                  ),
                                                  "label-for":
                                                    "startDateShooting",
                                                  "label-cols": "6",
                                                },
                                              },
                                              [
                                                _c("v-date-picker", {
                                                  attrs: {
                                                    "available-dates": {
                                                      start: new Date(
                                                        _vm.project.startDate
                                                      ),
                                                      end: new Date(
                                                        _vm.project.endDate
                                                      ),
                                                    },
                                                    locale: _vm.lang,
                                                    masks: _vm.masks,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          inputValue,
                                                          togglePopover,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "b-input-group",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        inputValue,
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Calendar"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#007A77",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4164318997
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.project
                                                        .shootingStartDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.project,
                                                        "shootingStartDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "project.shootingStartDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              !_vm.isFilmSingle &&
                              (_vm.project.analyticPeriodType === 2 ||
                                _vm.project.analyticPeriodType === 3 ||
                                _vm.project.analyticPeriodType === 4)
                                ? _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: 6 } }, [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    411,
                                                    "Wrap start date"
                                                  ),
                                                  "label-for": "startDateWrap",
                                                  "label-cols": "6",
                                                },
                                              },
                                              [
                                                _c("v-date-picker", {
                                                  attrs: {
                                                    "available-dates": {
                                                      start: new Date(
                                                        _vm.project.startDate
                                                      ),
                                                      end: new Date(
                                                        _vm.project.endDate
                                                      ),
                                                    },
                                                    locale: _vm.lang,
                                                    masks: _vm.masks,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          inputValue,
                                                          togglePopover,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "b-input-group",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        inputValue,
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Calendar"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#007A77",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4164318997
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.project.wrapStartDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.project,
                                                        "wrapStartDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "project.wrapStartDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: 4 } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: this.FormMSG(
                                                  900,
                                                  "Total filming"
                                                ),
                                                "label-cols": 6,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value: _vm.diffDatesFilm,
                                                      callback: function ($$v) {
                                                        _vm.diffDatesFilm = $$v
                                                      },
                                                      expression:
                                                        "diffDatesFilm",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "littleBorderRad",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  897,
                                                                  "days"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: 6 } },
                                    [
                                      _vm.project.projectType !== 3 &&
                                      _vm.project.projectType !== 4
                                        ? _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: this.FormMSG(
                                                  49,
                                                  "End date"
                                                ),
                                                "label-for": "endDate",
                                                "label-cols": "6",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  value:
                                                    _vm.project.endDate &&
                                                    _vm.project.endDate.split(
                                                      "T"
                                                    )[0],
                                                  type: "date",
                                                  id: "endDate",
                                                },
                                                on: {
                                                  input: _vm.updateEndDate,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: 4 } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: this.FormMSG(
                                              896,
                                              "Total production"
                                            ),
                                            "label-cols": 6,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: _vm.diffDates,
                                                  callback: function ($$v) {
                                                    _vm.diffDates = $$v
                                                  },
                                                  expression: "diffDates",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "littleBorderRad",
                                                      attrs: { disabled: "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              897,
                                                              "days"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(15, "Carbon calculator"),
                                    "label-for": "carbonCalculator",
                                    "label-cols": "3",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "carbonCalculator",
                                      disabled: !_vm.mapCarbonModels.length,
                                      options: _vm.mapCarbonModels,
                                    },
                                    model: {
                                      value: _vm.project.carbonModel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.project,
                                          "carbonModel",
                                          $$v
                                        )
                                      },
                                      expression: "project.carbonModel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4 &&
                              _vm.project.useReporting
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          51,
                                          "Green reporting"
                                        ),
                                        "label-for": "greenReporting",
                                        "label-cols": "3",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "greenReporting",
                                          disabled:
                                            !_vm.mapGreenReporting.length,
                                          options: _vm.mapGreenReporting,
                                        },
                                        model: {
                                          value: _vm.project.greenReporting,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "greenReporting",
                                              $$v
                                            )
                                          },
                                          expression: "project.greenReporting",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { lg: "9" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              507,
                                              "Payroll interface"
                                            ),
                                            "label-for": "payrollInterface",
                                            "label-cols": "4",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              id: "payrollInterface",
                                              options: _vm.mapPayrollInterface,
                                            },
                                            model: {
                                              value:
                                                _vm.project.payrollInterface,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.project,
                                                  "payrollInterface",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "project.payrollInterface",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.project.payrollInterface > 0
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "select_items_filter_wrapper",
                                          attrs: { lg: "3", id: "payrollCode" },
                                        },
                                        [
                                          _c("payroll-code", {
                                            attrs: { "style-type": "button" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "payrollCode",
                                        triggers: "hover",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            562,
                                            "Manage payroll codes..."
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.$screen.width > 567
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "9", offset: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  456,
                                                  "VAT for invoices"
                                                ),
                                                "label-cols": "3",
                                                horizontal: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                { attrs: { append: "%" } },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: "###",
                                                        expression: "'###'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        456,
                                                        "VAT for invoices"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.vatPercent,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "vatPercent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.vatPercent",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.displayLargeAccount
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass:
                                                "form-row align-items-center",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  1234,
                                                  "Large account"
                                                ),
                                                "label-for": "largeAccount",
                                                "label-cols": 3,
                                              },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  size: "lg",
                                                  id: "largeAccount",
                                                  disabled:
                                                    this.disableLargeAccount,
                                                },
                                                model: {
                                                  value:
                                                    _vm.project.largeAccount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "largeAccount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.largeAccount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.displayLargeAccount &&
                              _vm.project.largeAccount
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass:
                                                "form-row align-items-center",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  1135,
                                                  "Payment status"
                                                ),
                                                "label-for": "largeAccountPaid",
                                                "label-cols": 3,
                                              },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  size: "lg",
                                                  id: "largeAccountPaid",
                                                  disabled:
                                                    this.disableLargeAccount,
                                                },
                                                model: {
                                                  value:
                                                    _vm.project
                                                      .largeAccountPaid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.project,
                                                      "largeAccountPaid",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "project.largeAccountPaid",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { lg: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      65,
                                                      "Custom user title"
                                                    ),
                                                    "label-for":
                                                      "userTitleSideNavbar",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        69,
                                                        "e.g: User or Crew Member or Employee or ..."
                                                      ),
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.userName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "userName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.userName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { lg: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      70,
                                                      "Custom hod title"
                                                    ),
                                                    "label-for":
                                                      "hodTitleSideNavbar",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        71,
                                                        "e.g: Head of Department or ..."
                                                      ),
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.hodName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "hodName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.hodName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { lg: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      72,
                                                      "Custom production title"
                                                    ),
                                                    "label-for":
                                                      "productionTitleSideNavbar",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        73,
                                                        "e.g: Production or Management or ..."
                                                      ),
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project.managerName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "managerName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.managerName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.project.useExpense
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { lg: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          814,
                                                          "Max items per expense sheet"
                                                        ),
                                                        "label-for":
                                                          "productionTitleSideNavbar",
                                                        "label-cols": 3,
                                                        horizontal: true,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.project
                                                              .maxExpenseItemsPerSheet,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.project,
                                                              "maxExpenseItemsPerSheet",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "project.maxExpenseItemsPerSheet",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4 &&
                              _vm.project.greenReporting === 3
                                ? _c("div", { staticClass: "mb-2" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(
                                                    689,
                                                    "Options for carbon clap"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c("carbon-clap-option", {
                                              attrs: {
                                                "project-data": _vm.project,
                                              },
                                              on: {
                                                "carbon-clap-otpion:change":
                                                  _vm.handleCarbonClapOptionChange,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4
                                ? _c("div", { staticClass: "mb-2" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(
                                                    560,
                                                    "Options for budget"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      size: "lg",
                                                      id: "lockedBudget",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project
                                                          .budgetLocked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "budgetLocked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.budgetLocked",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          561,
                                                          "Locked budget"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4
                                ? _c("div", [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0 py-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(4010, "Features")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "h-100",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card card-border-blue-light",
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useEntr",
                                                                  disabled:
                                                                    !_vm.isDev,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlEntryForCrew(
                                                                        $event,
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useEntryForCrew,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useEntryForCrew",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useEntryForCrew",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        800,
                                                                        "Entry for crew"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                          id: "useEntrTimes",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleControlEntryForCrew(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useEntryForCrewTimesheet,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useEntryForCrewTimesheet",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useEntryForCrewTimesheet",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  300,
                                                                                  "Timesheets"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                          id: "useEntrExp",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleControlEntryForCrew(
                                                                                $event,
                                                                                2
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useEntryForCrewExpenses,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useEntryForCrewExpenses",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useEntryForCrewExpenses",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  400,
                                                                                  "Expenses"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                          id: "useEntrPo",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleControlEntryForCrew(
                                                                                $event,
                                                                                3
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useEntryForCrewPo,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useEntryForCrewPo",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useEntryForCrewPo",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  402,
                                                                                  "Purchase Orders"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "h-100",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card card-border-blue-light",
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useEntraa",
                                                                  disabled:
                                                                    !_vm.isDev,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleUseProd(
                                                                        $event,
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useProduction,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useProduction",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useProduction",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        8005,
                                                                        "Production"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useEntrTimess",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useCrewSetup,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useCrewSetup",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useCrewSetup",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  3050,
                                                                                  "Crew setup"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useEntrExpens",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useContacts,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useContacts",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useContacts",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  40014,
                                                                                  "Contacts"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useDoc",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useDocument,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useDocument",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useDocument",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              4050,
                                                                              "Documents & Packages"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useCallSheet",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useCallSheet,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useCallSheet",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useCallSheet",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              4055,
                                                                              "Call sheet"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                          id: "useAcc",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useAccommodation,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useAccommodation",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useAccommodation",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              403,
                                                                              "Accommodations"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useLocations",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useLocations,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useLocations",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useLocations",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              4040,
                                                                              "Locations"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTra",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useTransport,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useTransport",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useTransport",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              404,
                                                                              "Transports"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTran",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useExport,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useExport",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useExport",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              4041,
                                                                              "Export"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTrnsa",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleUseProd(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useImport,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useImport",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useImport",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              4042,
                                                                              "Import"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "h-100",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card card-border-blue-light",
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useFinance",
                                                                  disabled:
                                                                    !_vm.isDev,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleFinance(
                                                                        $event,
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useFinance,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useFinance",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useFinance",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        5800,
                                                                        "Finance"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useGS",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useGreenSupplier,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useGreenSupplier",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useGreenSupplier",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              407,
                                                                              "Green Suppliers"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "usePO",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .usePurchaseOrder,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "usePurchaseOrder",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.usePurchaseOrder",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              402,
                                                                              "Purchase Orders"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useExp",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                2
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useExpense,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useExpense",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useExpense",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                400,
                                                                                "Expenses"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          [
                                                                            _c(
                                                                              "b-row",
                                                                              [
                                                                                _c(
                                                                                  "b-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        offset:
                                                                                          "1",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pj-cb pb-1",
                                                                                        attrs:
                                                                                          {
                                                                                            size: "lg",
                                                                                            id: "useExpTravel",
                                                                                            disabled:
                                                                                              !_vm.isDev,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleControlExpense(
                                                                                                $event,
                                                                                                1
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .project
                                                                                                .useExpenseTravel,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.project,
                                                                                                  "useExpenseTravel",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "project.useExpenseTravel",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  501,
                                                                                                  "Travel"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "b-row",
                                                                              [
                                                                                _c(
                                                                                  "b-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        offset:
                                                                                          "1",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pj-cb pb-1",
                                                                                        attrs:
                                                                                          {
                                                                                            size: "lg",
                                                                                            disabled:
                                                                                              !_vm.isDev,
                                                                                            id: "useExpTicket",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleControlExpense(
                                                                                                $event,
                                                                                                2
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .project
                                                                                                .useExpenseTicket,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.project,
                                                                                                  "useExpenseTicket",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "project.useExpenseTicket",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  502,
                                                                                                  "Ticket"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "b-row",
                                                                              [
                                                                                _c(
                                                                                  "b-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        offset:
                                                                                          "1",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pj-cb pb-1",
                                                                                        attrs:
                                                                                          {
                                                                                            size: "lg",
                                                                                            disabled:
                                                                                              !_vm.isDev,
                                                                                            id: "useExpInvoice",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleControlExpense(
                                                                                                $event,
                                                                                                3
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .project
                                                                                                .useExpenseInvoice,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.project,
                                                                                                  "useExpenseInvoice",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "project.useExpenseInvoice",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  503,
                                                                                                  "Invoice"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTs",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useTimesheet,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useTimesheet",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useTimesheet",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              300,
                                                                              "Timesheets:"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTsPl",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useTimesheetPlanning,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useTimesheetPlanning",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useTimesheetPlanning",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              30074,
                                                                              "Timesheets planning"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useBud",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleFinance(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useBudget,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useBudget",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useBudget",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              409,
                                                                              "Budget"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "h-100",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card card-border-blue-light",
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useGreen",
                                                                  disabled:
                                                                    !_vm.isDev,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleGreen(
                                                                        $event,
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useGreen,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useGreen",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useGreen",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        80840,
                                                                        "Green"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useCarbTempl",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useCarbonTemplate,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useCarbonTemplate",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useCarbonTemplate",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  30710,
                                                                                  "Carbon template"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useGreenTable",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useGreenTable,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useGreenTable",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useGreenTable",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  40740,
                                                                                  "Green table"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      "offset-sm":
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useReport",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useReporting,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useReporting",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useReporting",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  40982,
                                                                                  "Reporting"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useCarbRemove",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useCarbonRemoval,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useCarbonRemoval",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useCarbonRemoval",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  40983,
                                                                                  "Carbon removal"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useWaste",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useWaste,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useWaste",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useWaste",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  40984,
                                                                                  "Waste"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          size: "lg",
                                                                          id: "useTOD",
                                                                          disabled:
                                                                            !_vm.isDev,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleGreen(
                                                                                $event,
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .project
                                                                              .useTipOfDay,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.project,
                                                                                "useTipOfDay",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "project.useTipOfDay",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              406,
                                                                              "Tip Of Day"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.project.projectType !== 3 &&
                              _vm.project.projectType !== 4
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(
                                                    652,
                                                    "Advanced options"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      size: "lg",
                                                      id: "allow_outside_c",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.allowEntryOutsideContract,
                                                      callback: function ($$v) {
                                                        _vm.allowEntryOutsideContract =
                                                          $$v
                                                      },
                                                      expression:
                                                        "allowEntryOutsideContract",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            653,
                                                            "Allow entry outside contract"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { staticClass: "ml-5" },
                                                      [
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "pj-cb pb-1",
                                                            attrs: {
                                                              size: "lg",
                                                              id: "allow_outside_c_timesheets",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.project
                                                                  .allowTSEntryOutsideContract,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "allowTSEntryOutsideContract",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "project.allowTSEntryOutsideContract",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    654,
                                                                    "Timesheets"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { staticClass: "ml-5" },
                                                      [
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "pj-cb pb-1",
                                                            attrs: {
                                                              size: "lg",
                                                              id: "allow_outside_c_expenses",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.project
                                                                  .allowExpenseEntryOutsideContract,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "allowExpenseEntryOutsideContract",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "project.allowExpenseEntryOutsideContract",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    400,
                                                                    "Expenses"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { staticClass: "ml-5" },
                                                      [
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "pj-cb pb-1",
                                                            attrs: {
                                                              size: "lg",
                                                              id: "allow_outside_c_po",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.project
                                                                  .allowPOEntryOutsideContract,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "allowPOEntryOutsideContract",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "project.allowPOEntryOutsideContract",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    402,
                                                                    "Purchase Orders"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      size: "lg",
                                                      id: "use_klippa_ocr",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project
                                                          .useKlippaOcr,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "useKlippaOcr",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.useKlippaOcr",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            655,
                                                            "OCR"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      size: "lg",
                                                      id: "hide_salary_info",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.project
                                                          .hideSalaryInfo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "hideSalaryInfo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "project.hideSalaryInfo",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            6505,
                                                            "Hide salary information"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.project.useTimesheet
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0 py-0 pb-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(
                                                    24,
                                                    "Time management"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card card-border-blue-light",
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "ovt",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "daily",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useOvertime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useOvertime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useOvertime",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        25,
                                                                        "Compute daily overtime:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "ovt1",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "daily",
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useOvt1,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useOvt1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useOvt1",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        325,
                                                                        "Compute daily overtime 1"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "ovt2",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "daily",
                                                                        3
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useOvt2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useOvt2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useOvt2",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        326,
                                                                        "Compute daily overtime 2:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useWeeklyOvt",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "weekly",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useWeeklyOvt,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useWeeklyOvt",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useWeeklyOvt",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        157,
                                                                        "Compute weekly overtime"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useWeeklyOvt1",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "weekly",
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useWeeklyOvt1,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useWeeklyOvt1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useWeeklyOvt1",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        158,
                                                                        "Compute weekly overtime 2"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useWeeklyOvt2",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleControlOvertime(
                                                                        $event,
                                                                        "weekly",
                                                                        3
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useWeeklyOvt2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useWeeklyOvt2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useWeeklyOvt2",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        159,
                                                                        "Compute weekly overtime 3"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card card-border-blue-light",
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "cnh",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useNightHours,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useNightHours",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useNightHours",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        26,
                                                                        "Compute night hours:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "tbtwwd",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useTooEarlyHours,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useTooEarlyHours",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useTooEarlyHours",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        27,
                                                                        "Use rest time"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "chtwt",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useHomeToWorkTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useHomeToWorkTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useHomeToWorkTime",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        336,
                                                                        "Compute home to work time"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "ult",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useLunchTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useLunchTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useLunchTime",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        29,
                                                                        "Use lunch time:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            !_vm.isFilmSingle
                                                              ? _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "usd",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .project
                                                                          .useStageDay,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.project,
                                                                            "useStageDay",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "project.useStageDay",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            30,
                                                                            "Use stage day:"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "sdsr",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useSixthDay,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useSixthDay",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useSixthDay",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        28,
                                                                        "Use sixth day special rate:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useSeventhDay",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useSeventhDay,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useSeventhDay",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useSeventhDay",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        161,
                                                                        "Use 7th special rate"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "useSundayRate",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useSundayRate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useSundayRate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useSundayRate",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        160,
                                                                        "Use sunday special rate"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card card-border-blue-light",
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "ulpd",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useLunchPerDiem,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useLunchPerDiem",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useLunchPerDiem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        139,
                                                                        "Use lunch per diem:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "dinnerPerDiem",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useDinnerPerDiem,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useDinnerPerDiem",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useDinnerPerDiem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        301,
                                                                        "Use dinner per diem"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "uhpd",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useHotelPerDiem,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useHotelPerDiem",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useHotelPerDiem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        130,
                                                                        "Use hotel per diem:"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                  id: "abroadPerDiem",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.project
                                                                      .useAbroadPerDiem,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.project,
                                                                        "useAbroadPerDiem",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "project.useAbroadPerDiem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        302,
                                                                        "Use abroad per diem"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            !_vm.isFilmSingle
                                                              ? _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      id: "ubct",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .project
                                                                          .useBeforeCallTime,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.project,
                                                                            "useBeforeCallTime",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "project.useBeforeCallTime",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            201,
                                                                            "Use before call time"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "4",
                                                          lg: "4",
                                                          "offset-md": "8",
                                                          "offset-lg": "8",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              block: "",
                                                              variant:
                                                                "outline-primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.showHolidaysModal = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    245,
                                                                    "Manage public holidays"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isFilmSingle
                                          ? _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "text-center",
                                                    class: `${
                                                      _vm.$screen.width <= 576
                                                        ? "pt-3"
                                                        : ""
                                                    }`,
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass: "btn",
                                                        class: `${
                                                          _vm.$screen.width <=
                                                          576
                                                            ? "w-100"
                                                            : "w-50"
                                                        }`,
                                                        attrs: {
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.loadCcnForSolo,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                251,
                                                                "Load French CCN cinema salaries and functions"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.project.projectType === 0 ||
                              _vm.project.projectType === 1 ||
                              _vm.project.projectType === 4
                                ? _c("div", [
                                    _vm.project.projectType === 1
                                      ? _c(
                                          "fieldset",
                                          {
                                            staticClass: "my-2 py-0",
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "legend",
                                              {
                                                class: `${
                                                  _vm.$screen.width <= 576
                                                    ? "card-inside"
                                                    : "scheduler-border"
                                                }`,
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        31,
                                                        "Offer Parameter"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "pb-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert alert-warning",
                                                  attrs: { role: "alert" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "pr-3",
                                                          staticStyle: {
                                                            "font-size":
                                                              "1.5rem",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-exclamation-triangle",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "mt-0",
                                                          style: `font-size: ${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? ".9"
                                                              : "1"
                                                          }rem;`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  32,
                                                                  `You should specify your own offer number format, otherwise the default format will be chosen automatically. e.g: "1"`
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-radio-group", {
                                                    staticClass: "lh-22",
                                                    attrs: {
                                                      options:
                                                        _vm.radioOfferOptions,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeOfferParameter,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRadioOffer,
                                                      callback: function ($$v) {
                                                        _vm.selectedRadioOffer =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedRadioOffer",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-block mt-2" },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 pb-3 w-100",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-3 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    36,
                                                                    "Preview"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      37,
                                                                      "e.g:"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pl-1 last-number-format",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.renderOfferFormat
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      !_vm.selectedRadioOffer
                                                        ? _c("div", [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-2 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            61,
                                                                            "Define length"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "range",
                                                                                min: "1",
                                                                                max: "10",
                                                                                step: "1",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.handleChangeOfferInitialLength,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.offerInitialLength,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.offerInitialLength =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "offerInitialLength",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pl-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "10"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100 text-center text-success",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.offerInitialLength
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-3 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            35,
                                                                            "Choose year"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-radio-group",
                                                                      {
                                                                        staticClass:
                                                                          "lh-22",
                                                                        attrs: {
                                                                          options:
                                                                            _vm.radioOfferYearOptions,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.handleChangeOfferYear,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedRadioOfferYear,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedRadioOfferYear =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectedRadioOfferYear",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-0 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            33,
                                                                            "Define prefix"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-for":
                                                                                  "offerInitialText",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      _vm.FormMSG(
                                                                                        60,
                                                                                        "e.g: TGS ..."
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.handleChangeOfferInitialText,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.offerInitialText,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.offerInitialText =
                                                                                          typeof $$v ===
                                                                                          "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                      },
                                                                                    expression:
                                                                                      "offerInitialText",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-0 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      68,
                                                                      "Enter your last number"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        "label-for":
                                                                          "offerLastId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              step: "1",
                                                                              min: "0",
                                                                              max: "100000",
                                                                              placeholder:
                                                                                "e.g: 101",
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastOfferNumber
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              _vm.handleChangeOfferLastNumber,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .project
                                                                                  .lastOfferNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.project,
                                                                                    "lastOfferNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "project.lastOfferNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-form-invalid-feedback",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastOfferNumber
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  34,
                                                                                  "Length must be 0-100000"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.project.projectType === 1
                                      ? _c(
                                          "fieldset",
                                          {
                                            staticClass: "my-2 py-0",
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "legend",
                                              {
                                                class: `${
                                                  _vm.$screen.width <= 576
                                                    ? "card-inside"
                                                    : "scheduler-border"
                                                }`,
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        38,
                                                        "Order Parameter"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "pb-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert alert-warning",
                                                  attrs: { role: "alert" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "pr-3",
                                                          staticStyle: {
                                                            "font-size":
                                                              "1.5rem",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-exclamation-triangle",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "mt-0",
                                                          style: `font-size: ${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? ".9"
                                                              : "1"
                                                          }rem;`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  39,
                                                                  `You should specify your own order number format, otherwise the default format will be chosen automatically. e.g: "1"`
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-radio-group", {
                                                    staticClass: "lh-22",
                                                    attrs: {
                                                      options:
                                                        _vm.radioOrderOptions,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeOrderParameter,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRadioOrder,
                                                      callback: function ($$v) {
                                                        _vm.selectedRadioOrder =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedRadioOrder",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-block mt-2" },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 pb-3 w-100",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-3 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    36,
                                                                    "Preview"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      37,
                                                                      "e.g:"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pl-1 last-number-format",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.renderOrderFormat
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      !_vm.selectedRadioOrder
                                                        ? _c("div", [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-2 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            61,
                                                                            "Define length"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "range",
                                                                                min: "1",
                                                                                max: "10",
                                                                                step: "1",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.handleChangeOrderInitialLength,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.orderInitialLength,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.orderInitialLength =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "orderInitialLength",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pl-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "10"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100 text-center text-success",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.orderInitialLength
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-3 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            35,
                                                                            "Choose year"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-radio-group",
                                                                      {
                                                                        staticClass:
                                                                          "lh-22",
                                                                        attrs: {
                                                                          options:
                                                                            _vm.radioOrderYearOptions,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.handleChangeOrderYear,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedRadioOrderYear,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedRadioOrderYear =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectedRadioOrderYear",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-0 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            33,
                                                                            "Define prefix"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-for":
                                                                                  "orderInitialText",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      _vm.FormMSG(
                                                                                        60,
                                                                                        "e.g: TGS ..."
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.handleChangeOrderInitialText,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.orderInitialText,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.orderInitialText =
                                                                                          typeof $$v ===
                                                                                          "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                      },
                                                                                    expression:
                                                                                      "orderInitialText",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-0 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      68,
                                                                      "Enter your last number"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        "label-for":
                                                                          "orderLastId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              step: "1",
                                                                              min: "0",
                                                                              max: "100000",
                                                                              placeholder:
                                                                                "e.g: 101",
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastOrderNumber
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              _vm.handleChangeOrderLastNumber,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .project
                                                                                  .lastOrderNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.project,
                                                                                    "lastOrderNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "project.lastOrderNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-form-invalid-feedback",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastOrderNumber
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  34,
                                                                                  "Length must be 0-100000"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.project.projectType === 1 ||
                                    _vm.project.projectType === 4
                                      ? _c(
                                          "fieldset",
                                          {
                                            staticClass: "my-0 py-0 mt-3",
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "legend",
                                              {
                                                class: `${
                                                  _vm.$screen.width <= 576
                                                    ? "card-inside"
                                                    : "scheduler-border"
                                                }`,
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        40,
                                                        "Invoice Parameter"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "pb-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert alert-warning",
                                                  attrs: { role: "alert" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "pr-3",
                                                          staticStyle: {
                                                            "font-size":
                                                              "1.5rem",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-exclamation-triangle",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "mt-0",
                                                          style: `font-size: ${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? ".9"
                                                              : "1"
                                                          }rem;`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  41,
                                                                  `You should specify your own invoice number format, otherwise the default format will be chosen automatically. e.g: "1"`
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-radio-group", {
                                                    staticClass: "lh-22",
                                                    attrs: {
                                                      options:
                                                        _vm.radioInvoiceOptions,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeInvoiceParameter,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRadioInvoice,
                                                      callback: function ($$v) {
                                                        _vm.selectedRadioInvoice =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedRadioInvoice",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-block mt-2" },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 pb-3 w-100",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-3 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      36,
                                                                      "Preview"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      37,
                                                                      "e.g:"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pl-1 last-number-format",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.renderInvoiceFormat
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      !_vm.selectedRadioInvoice
                                                        ? _c("div", [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-2 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            61,
                                                                            "Define length"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "range",
                                                                                min: "1",
                                                                                max: "10",
                                                                                step: "1",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.handleChangeInvoiceInitialLength,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.invoiceInitialLength,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.invoiceInitialLength =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "invoiceInitialLength",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pl-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "10"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100 text-center text-success",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.invoiceInitialLength
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-3 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            35,
                                                                            "Choose year"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-radio-group",
                                                                      {
                                                                        staticClass:
                                                                          "lh-22",
                                                                        attrs: {
                                                                          options:
                                                                            _vm.radioInvoiceYearOptions,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.handleChangeInvoiceYear,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedRadioInvoiceYear,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedRadioInvoiceYear =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectedRadioInvoiceYear",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-0 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            33,
                                                                            "Define prefix"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-for":
                                                                                  "invoiceInitialText",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      _vm.FormMSG(
                                                                                        60,
                                                                                        "e.g: TGS ..."
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.handleChangeInvoiceInitialText,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.invoiceInitialText,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.invoiceInitialText =
                                                                                          typeof $$v ===
                                                                                          "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                      },
                                                                                    expression:
                                                                                      "invoiceInitialText",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-0 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      68,
                                                                      "Enter your last number"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        "label-for":
                                                                          "invoiceLastId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              step: "1",
                                                                              min: "0",
                                                                              max: "100000",
                                                                              placeholder:
                                                                                "e.g: 101",
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastInvoiceNumber
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              _vm.handleChangeInvoiceLastNumber,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .project
                                                                                  .lastInvoiceNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.project,
                                                                                    "lastInvoiceNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "project.lastInvoiceNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-form-invalid-feedback",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastInvoiceNumber
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  34,
                                                                                  "Length must be 0-100000"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.project.projectType === 0
                                      ? _c(
                                          "fieldset",
                                          {
                                            staticClass: "my-0 py-0 mt-3",
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "legend",
                                              {
                                                class: `${
                                                  _vm.$screen.width <= 576
                                                    ? "card-inside"
                                                    : "scheduler-border"
                                                }`,
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        740,
                                                        "Purchase Order parameter"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "pb-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert alert-warning",
                                                  attrs: { role: "alert" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "pr-3",
                                                          staticStyle: {
                                                            "font-size":
                                                              "1.5rem",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-exclamation-triangle",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "mt-0",
                                                          style: `font-size: ${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? ".9"
                                                              : "1"
                                                          }rem;`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  41,
                                                                  `You should specify your own invoice number format, otherwise the default format will be chosen automatically. e.g: "1"`
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-radio-group", {
                                                    staticClass: "lh-22",
                                                    attrs: {
                                                      options:
                                                        _vm.radioInvoiceOptions,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangePoParameter,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRadioPo,
                                                      callback: function ($$v) {
                                                        _vm.selectedRadioPo =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedRadioPo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-block mt-2" },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 pb-3 w-100",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-3 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      36,
                                                                      "Preview"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      37,
                                                                      "e.g:"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pl-1 last-number-format",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.renderPoFormat
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      !_vm.selectedRadioPo
                                                        ? _c("div", [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-2 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            61,
                                                                            "Define length"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "range",
                                                                                min: "1",
                                                                                max: "10",
                                                                                step: "1",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.handleChangePoInitialLength,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.poInitialLength,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.poInitialLength =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "poInitialLength",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pl-2",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "10"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "justify-content-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-100 text-center text-success",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.poInitialLength
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-3 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            35,
                                                                            "Choose year"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-radio-group",
                                                                      {
                                                                        staticClass:
                                                                          "lh-22",
                                                                        attrs: {
                                                                          options:
                                                                            _vm.radioInvoiceYearOptions,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.handleChangePoYear,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedRadioPoYear,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedRadioPoYear =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectedRadioPoYear",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "my-0 pb-0 w-100",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <=
                                                                      576
                                                                        ? "card-inside"
                                                                        : "scheduler-border"
                                                                    }`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            33,
                                                                            "Define prefix"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-for":
                                                                                  "invoiceInitialText",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      _vm.FormMSG(
                                                                                        60,
                                                                                        "e.g: PO ..."
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.handleChangePoInitialText,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.poInitialText,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.poInitialText =
                                                                                          typeof $$v ===
                                                                                          "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                      },
                                                                                    expression:
                                                                                      "poInitialText",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "my-0 pb-0 w-100",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      68,
                                                                      "Enter your last number"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        "label-for":
                                                                          "invoiceLastId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              step: "1",
                                                                              min: "0",
                                                                              max: "100000",
                                                                              placeholder:
                                                                                "e.g: 101",
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastPoNumber
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              _vm.handleChangePoLastNumber,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .project
                                                                                  .lastPoNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.project,
                                                                                    "lastPoNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "project.lastPoNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-form-invalid-feedback",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              state:
                                                                                _vm.lastNumberValidation(
                                                                                  _vm
                                                                                    .project
                                                                                    .lastPoNumber
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  34,
                                                                                  "Length must be 0-100000"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.isModal
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  class: `${
                                    _vm.$screen.width < 992 ? "mt-3" : ""
                                  }`,
                                  attrs: { sm: "3" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: `${
                                        _vm.$screen.width > 576
                                          ? "sidenav-sticky sticky-top"
                                          : ""
                                      }`,
                                      staticStyle: { "z-index": "0" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "footer-fixed"
                                              : "card mb-2 pb-0 px-0"
                                          }`,
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "w-100 d-flex justify-content-center align-items-center",
                                                  attrs: {
                                                    type: "submit",
                                                    variant: "outline-primary",
                                                    disabled: _vm.disableSave,
                                                  },
                                                },
                                                [
                                                  _c("b-spinner", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.isLoading,
                                                        expression: "isLoading",
                                                      },
                                                    ],
                                                    staticClass: "mr-2",
                                                    attrs: { small: "" },
                                                  }),
                                                  !_vm.isLoading
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "Check"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2.5,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          this.addSaveLabel()
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              this.showGoBack
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "btn w-100",
                                                      attrs: {
                                                        variant: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.viewUsers,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          this.FormMSG(
                                                            17,
                                                            "Users"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              this.showGoBack
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "btn w-100",
                                                      attrs: {
                                                        variant: "primary",
                                                      },
                                                      on: { click: _vm.goBack },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          this.FormMSG(
                                                            10,
                                                            "Go back"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              this.showAddPme
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "btn w-100",
                                                      attrs: {
                                                        variant: "primary",
                                                      },
                                                      on: { click: _vm.addPme },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          this.FormMSG(
                                                            110,
                                                            "Add new Legal Entity"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm.$screen.width <= 567 ||
                                              _vm.$isPwa()
                                                ? _c(
                                                    "MobileProfilePicCaptureBtn",
                                                    {
                                                      attrs: {
                                                        "parent-type":
                                                          "project",
                                                        parentId:
                                                          _vm.project.id,
                                                        "label-button":
                                                          _vm.FormMSG(
                                                            43,
                                                            "Change project picture"
                                                          ),
                                                        "crop-type": "default",
                                                        "use-button": "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleImgsTaken,
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm.$screen.width > 567
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "w-100 mt-0",
                                                      attrs: {
                                                        variant:
                                                          "outline-secondary",
                                                        size: "md",
                                                      },
                                                      on: {
                                                        click: _vm.startCapture,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-camera",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            this.FormMSG(
                                                              43,
                                                              "Change project picture"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  model: {
                                                    value: _vm.project.picture,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.project,
                                                        "picture",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "project.picture",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "project-picture-preview",
                                                    attrs: {
                                                      src: this.imageName,
                                                      alt: "pict",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("NewCapture", {
          key: _vm.refreshTokenCaptureItem,
          attrs: {
            parentType: "project",
            parentId: _vm.project.id,
            "auto-crop-capture": "",
          },
          on: { change: _vm.handleImgsTaken },
          model: {
            value: _vm.capturePict,
            callback: function ($$v) {
              _vm.capturePict = $$v
            },
            expression: "capturePict",
          },
        }),
        _c("holidays-modal", {
          attrs: { open: _vm.showHolidaysModal },
          on: {
            "holidays-modal:close": function ($event) {
              _vm.showHolidaysModal = false
            },
          },
        }),
        _c("loading", {
          attrs: { active: _vm.isLoading, "is-full-page": true },
          on: {
            "update:active": function ($event) {
              _vm.isLoading = $event
            },
          },
        }),
        _c(
          "b-modal",
          {
            ref: "my-modal",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              title: "Crop Picture",
              size: "cropSize",
              "ok-variant": "success",
              "ok-title": "Confirm Save",
              "cancel-title": "Cancel",
              "modal-class": "mui-animation",
              fade: false,
            },
            on: { ok: _vm.endCrop, cancel: _vm.endCropCancel },
            model: {
              value: _vm.cropPict,
              callback: function ($$v) {
                _vm.cropPict = $$v
              },
              expression: "cropPict",
            },
          },
          [_c("CropPicture", { ref: "myCropPicture" })],
          1
        ),
        _c(
          "b-modal",
          {
            staticClass: "modal-dialog modal-lg",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              title: _vm.FormMSG(62, "Create new Legal Entity?"),
              "ok-variant": "primary",
              "ok-title": this.FormMSG(67, "Create Entity"),
              "modal-class": "mui-animation",
              fade: false,
            },
            on: {
              ok: function ($event) {
                return _vm.newPmeConfirmed()
              },
            },
            model: {
              value: _vm.newPmeModal,
              callback: function ($$v) {
                _vm.newPmeModal = $$v
              },
              expression: "newPmeModal",
            },
          },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.FormMSG(109, "New Entity Name:"),
                  "label-for": "pmeName",
                  "label-cols": 6,
                },
              },
              [
                _c("b-form-input", {
                  attrs: { type: "text", id: "pmeName" },
                  model: {
                    value: _vm.newPmeName,
                    callback: function ($$v) {
                      _vm.newPmeName = $$v
                    },
                    expression: "newPmeName",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "form-row align-items-center",
                attrs: {
                  label: _vm.FormMSG(
                    120,
                    "Copy Departments, Functions, Budget categories and HR templates of this entity?"
                  ),
                  "label-for": "copyPmeConfig",
                  "label-cols": 10,
                },
              },
              [
                _c("b-form-checkbox", {
                  attrs: { size: "lg", id: "copyPmeConfig" },
                  model: {
                    value: _vm.copyPmeConfig,
                    callback: function ($$v) {
                      _vm.copyPmeConfig = $$v
                    },
                    expression: "copyPmeConfig",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }