<template>
	<div>
		<b-row>
			<b-col sm="12" md="9" lg="9" xl="9">
				<b-form-group :label="FormMSG(65, 'Carbon clap share reporting')" label-cols="4">
					<v-select v-model="ccShareReportings" :options="ccShareReportingOptions" multiple @input="handleInputCcShareReportings" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-button block variant="primary" @click="showModalLogin = true">
					<component :is="getLucideIcon('KeyRound')" :size="16" />
					{{ loginCaption }}
				</b-button>
			</b-col>
		</b-row>

		<b-modal
			v-model="showModalLogin"
			header-class="header-class-modal-doc-package"
			:title="FormMSG(70, 'Carbon clap login')"
			:ok-title="FormMSG(71, 'Save')"
			:cancel-title="FormMSG(72, 'Cancel')"
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			ok-variant="primary"
			:ok-disable="dataForLogin.ccLogin !== '' && dataForLogin.ccPassword !== ''"
			cancel-variant="outline-primary"
			@ok.prevent="handleOk"
			@hidden="emitEventClose"
			modal-class="mui-animation"
			:fade="false"
		>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(78, 'Login')">
						<b-form-input
							v-model="$v.dataForLogin.ccLogin.$model"
							:placeholder="FormMSG(81, 'Enter your login')"
							:state="!$v.dataForLogin.ccLogin.$error"
						/>
						<div v-if="$v.dataForLogin.ccLogin.$error" class="invalid-feedback">
							{{ FormMSG(16, 'Login required') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(79, 'Password')">
						<b-form-input
							v-model="$v.dataForLogin.ccPassword.$model"
							type="password"
							:placeholder="FormMSG(82, 'Enter password')"
							:state="!$v.dataForLogin.ccPassword.$error"
						/>
						<div v-if="$v.dataForLogin.ccPassword.$error" class="invalid-feedback">
							{{ FormMSG(12, 'Password required') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
	name: 'CarbonClapOption',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		projectData: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			dataToSend: {
				ccLogin: '',
				ccPassword: '',
				ccShareReporting: ''
			},

			dataForLogin: {
				ccLogin: '',
				ccPassword: ''
			},

			showModalLogin: false,
			ccShareReportings: []
		};
	},

	validations: {
		dataForLogin: {
			ccLogin: { required },
			ccPassword: { required }
		}
	},

	watch: {
		projectData: {
			handler(newVal) {
				this.dataToSend.ccLogin = newVal.ccLogin;
				this.dataToSend.ccPassword = newVal.ccPassword;
				this.explodeCcShareReporting(newVal.ccShareReporting);

				if (this.dataToSend.ccLogin !== '') {
					this.dataForLogin.ccLogin = this.dataToSend.ccLogin;
				}
			},
			immediate: true,
			deep: true
		},
		dataToSend: {
			handler(newVal) {
				this.dataToSend.ccShareReporting = this.ccShareReportings.join(',');
				this.$emit('carbon-clap-otpion:change', this.dataToSend);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		loginCaption() {
			if (this.dataToSend.ccLogin !== '') {
				return this.FormMSG(69, 'Update info login');
			}
			return this.FormMSG(66, 'Add info login');
		},
		ccShareReportingOptions() {
			const menues = this.FormMenu(11022);
			let result = [];
			menues.map((option) => {
				result.push(option.text);
			});

			return result;
		}
	},

	methods: {
		handleInputCcShareReportings() {
			this.dataToSend.ccShareReporting = this.ccShareReportings.join(',');
		},
		handleOk() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.dataToSend.ccLogin = _.cloneDeep(this.dataForLogin.ccLogin);
				this.dataToSend.ccPassword = _.cloneDeep(this.dataForLogin.ccPassword);

				this.showModalLogin = false;
			}
		},
		emitEventClose() {
			this.dataForLogin.ccLogin = '';
			this.dataForLogin.ccPassword = '';
		},
		explodeCcShareReporting(value) {
			if (value !== '') {
				this.ccShareReportings = value.split(',');
			}
		}
	}
};
</script>

<style scoped></style>
